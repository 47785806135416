import { TOption } from "@/types/Option";
import ApiService from "../services/api.service";

type TOptionState = {
    loading: boolean;
    options: Array<TOption>;
};

const state: TOptionState = {
    loading: false,
    options: [],
};

const getters = {
    getAllOptions: (state: TOptionState) => state.options,
    withValues: (state: TOptionState) => state.options.filter((o) => o.values.length > 0),
    getById: (state: TOptionState) => (id: number) => state.options.find((o) => o.id == id),
    getValueById: (state: TOptionState) => (optionId: number, valueId: number) =>
        state.options.find((o) => o.id == optionId)?.values.find((v) => v.id === valueId),
    getOptionValues: (state: TOptionState) => (id: number) =>
        state.options.find((o) => o.id == id)?.values ?? [],
};

const actions = {
    async fetchOptions(context: any) {
        context.commit("loadingOptions");
        try {
            const result = await ApiService.get("/options?values=1");
            context.commit("setOptions", { data: result.data.result });
        } catch (err) {
            console.log(err);
        }
    },
    async addOption(context: any, option: any) {
        context.commit("loadingOptions");
        try {
            const result = await ApiService.post("/options", option);
            context.commit("setOption", { data: result.data });
        } catch (err) {
            console.error(err);
        }
    },
    async editOption(context: any, option: any) {
        context.commit("loadingOptions");
        try {
            const result = await ApiService.put(`/options/${option.id}`, option);
            context.commit("updateOption", { data: result.data });
        } catch (err) {
            console.error(err);
        }
    },
    async deleteOption(context: any, id: number) {
        context.commit("loadingOptions");
        try {
            await ApiService.delete(`/options/${id}`);
            context.commit("removeOption", { id });
        } catch (err) {
            console.error(err);
        }
    },
    async addValue(context: any, value: any) {
        context.commit("loadingOptions");
        try {
            const result = await ApiService.post("/values", value);
            context.commit("setValue", { data: result.data });
        } catch (err) {
            console.error(err);
        }
    },
    async deleteValue(context: any, data: { id: number; optionId: number }) {
        context.commit("loadingOptions");
        try {
            await ApiService.delete(`/values/${data.id}`);
            context.commit("removeValue", data);
        } catch (err) {
            console.error(err);
        }
    },
};

const mutations = {
    loadingOptions(state: TOptionState) {
        state.loading = true;
    },
    setOption(state: TOptionState, payload: { data: any }) {
        state.loading = false;
        state.options = [...state.options, payload.data];
    },
    updateOption(state: TOptionState, payload: { data: any }) {
        state.options = state.options.map((op: { id: number }) =>
            payload.data.id == op.id ? { ...op, ...payload.data } : op
        );
        state.loading = false;
    },
    removeOption(state: TOptionState, payload: { id: number }) {
        state.options = state.options.filter((op: { id: number }) => op.id != payload.id);
        state.loading = false;
    },
    setOptions(state: TOptionState, payload: { data: Array<any> }) {
        state.loading = false;
        state.options = payload.data;
    },
    setValue(state: TOptionState, payload: { data: any }) {
        state.loading = false;
        const option = Object.assign(
            {},
            state.options.find((op) => op.id == payload.data.option_id)
        );
        option.values = [...option.values, payload.data];
        state.options = [
            ...state.options.map((op) => (op.id === option.id ? { ...option } : { ...op })),
        ];
    },
    removeValue(state: TOptionState, payload: { id: number; optionId: number }) {
        state.loading = false;
        const option = Object.assign(
            {},
            state.options.find((op) => op.id == payload.optionId)
        );
        option.values = [...option.values.filter((v: any) => v.id != payload.id)];
        state.options = [
            ...state.options.map((op) => (op.id === option.id ? { ...option } : { ...op })),
        ];
    },
};

export const option = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
