const TOKEN_KEY = "token";
// const REFRESH_TOKEN_KEY = "refresh_token";

const TokenService = {
    getToken() {
        return localStorage.getItem(TOKEN_KEY);
    },

    saveToken(accessToken: string) {
        localStorage.setItem(TOKEN_KEY, accessToken);
    },

    removeToken() {
        localStorage.removeItem(TOKEN_KEY);
    },

    // getRefreshToken() {
    //     return localStorage.getItem(REFRESH_TOKEN_KEY);
    // },

    // saveRefreshToken(refreshToken: string) {
    //     localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    // },

    // removeRefreshToken() {
    //     localStorage.removeItem(REFRESH_TOKEN_KEY);
    // }
};

export { TokenService };