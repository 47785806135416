import { TUser } from "@/types/User";
import ApiService from "../services/api.service";

type TUserState = { loading: boolean; users: Array<TUser>; error: null | string };

const state: TUserState = {
    loading: false,
    users: [],
    error: null,
};

const getters = {
    all: (state: TUserState) => state.users,
    byId: (state: TUserState) => (id: number) => state.users.find((u) => u.id == id),
};

const actions = {
    async fetchAll(context: any) {
        context.commit("loadingUsers");
        try {
            const result = await ApiService.get("/users");
            context.commit("setUsers", { users: result.data.result });
        } catch (err) {
            console.log(err);
            context.commit("setError", { error: err });
        }
    },
    async create(context: any, userData: Omit<TUser, "id">) {
        context.commit("loadingUsers");
        try {
            const result = await ApiService.post("/users", userData);
            context.commit("addUser", { user: result.data });
        } catch (err) {
            console.log(err);
            context.commit("setError", { error: err });
        }
    },
    async update(context: any, userData: Partial<TUser>) {
        context.commit("loadingUsers");
        try {
            const result = await ApiService.put(`/users/${userData.id}`, userData);
            context.commit("updateUser", { user: result.data });
        } catch (err) {
            console.log(err);
            context.commit("setError", { error: err });
        }
    },
    async delete(context: any, id: number) {
        context.commit("loadingUsers");
        try {
            await ApiService.delete(`/users/${id}`);
            context.commit("removeUser", { id });
        } catch (err) {
            console.log(err);
            context.commit("setError", { error: err });
        }
    },
};

const mutations = {
    loadingUsers(state: TUserState) {
        state.loading = true;
    },
    setUsers(state: TUserState, payload: { users: Array<TUser> }) {
        state.loading = false;
        state.users = payload.users;
    },
    addUser(state: TUserState, payload: { user: TUser }) {
        state.users = [...state.users, payload.user];
        state.loading = false;
    },
    updateUser(state: TUserState, payload: { user: TUser }) {
        state.loading = false;
        state.users = state.users.map((u) => (u.id == payload.user.id ? payload.user : { ...u }));
    },
    removeUser(state: TUserState, payload: { id: number }) {
        state.loading = false;
        state.users = [...state.users.filter((u: { id: number }) => u.id != payload.id)];
    },
    setError(state: TUserState, payload: { error: string }) {
        state.loading = false;
        state.error = payload.error;
    },
    clearError(state: TUserState) {
        state.loading = false;
        state.error = null;
    },
};

export const user = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
