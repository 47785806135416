import { TProduct } from "@/types/Product";
import ApiService from "../services/api.service";

type TProductState = {
    loading: boolean;
    products: Array<TProduct>;
    product: TProduct | null;
};

const state: TProductState = {
    loading: false,
    products: [],
    product: null,
};

const getters = {
    getAllProducts: (state: TProductState) => state.products,
    getById: (state: TProductState) => (id: number) => state.products.find((p) => p.id == id),
    getByBrand: (state: TProductState) => (brand: string) =>
        state.products.find((p) => p.brand == brand),
    getByCategory: (state: TProductState) => (category: number) =>
        state.products.find((p) => p.category_id == category),
    selected: (state: TProductState) => state.product,
};

const actions = {
    async fetchProducts(context: any) {
        context.commit("loadingProducts");
        try {
            const result = await ApiService.get("/products");
            context.commit("setProducts", { data: result.data.results });
        } catch (err) {
            console.log(err);
            context.commit("setError");
        }
    },
    async fetchProduct(context: any, id: number) {
        context.commit("loadingProducts");
        try {
            const result = await ApiService.get(`/products/${id}`);
            context.commit("setProduct", { data: result.data });
        } catch (err) {
            console.log(err);
            context.commit("setError");
        }
    },
    async createProduct(context: any, productData: any) {
        context.commit("loadingProducts");
        try {
            const result = await ApiService.post("/products", productData);
            context.commit("addProduct", { data: result.data });
        } catch (err) {
            console.log(err);
            context.commit("setError");
        }
    },
    async updateProduct(context: any, productData: any) {
        context.commit("loadingProducts");
        try {
            const result = await ApiService.put(`/products/${productData.id}`, productData);
            context.commit("updateProduct", { data: result.data });
        } catch (err) {
            console.log(err);
            context.commit("setError");
        }
    },
    async deleteProduct(context: any, id: number) {
        context.commit("loadingProducts");
        try {
            await ApiService.delete(`/products/${id}`);
            context.commit("removeProduct", { id });
        } catch (err) {
            console.log(err);
            context.commit("setError");
        }
    },
};

const mutations = {
    loadingProducts(state: TProductState) {
        state.loading = true;
    },
    setProducts(state: TProductState, payload: { data: Array<any> }) {
        state.loading = false;
        state.products = payload.data;
    },
    addProduct(state: TProductState, payload: { data: any }) {
        state.products = [payload.data, ...state.products];
        state.product = payload.data.id;
        state.loading = false;
    },
    setProduct(state: TProductState, payload: { data: any }) {
        state.loading = false;
        state.product = payload.data;
    },
    updateProduct(state: TProductState, payload: { data: any }) {
        state.loading = false;
        state.products = state.products.map((p: any) =>
            p.id == payload.data.id ? payload.data : p
        );
    },
    removeProduct(state: TProductState, payload: { id: number }) {
        state.loading = false;
        state.products = state.products.filter((p: { id: number }) => p.id != payload.id);
    },
    setError(state: TProductState) {
        state.loading = false;
    },
};

export const product = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
