import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

import { defineCustomElements } from "@ionic/pwa-elements/loader";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import ApiService from "./services/api.service";
import { TokenService } from "./services/token.service";
import { store } from "./store";

const app = createApp(App).use(IonicVue).use(router).use(store);

// ApiService.init(process.env.VUE_APP_HOST_PORT);
ApiService.init("https://officeworks-v1.moorup.com.au/api");

if (TokenService.getToken()) {
  ApiService.setHeader();
  ApiService.mountRequestInterceptor();
  ApiService.mount401Interceptor();
}

defineCustomElements(window);

router.isReady().then(() => {
  app.mount("#app");
});
