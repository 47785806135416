
import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    // IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonButton,
    IonHeader,
    IonToolbar
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
    archiveOutline,
    archiveSharp,
    bookmarkOutline,
    bookmarkSharp,
    heartOutline,
    heartSharp,
    mailOutline,
    mailSharp,
    paperPlaneOutline,
    paperPlaneSharp,
    trashOutline,
    trashSharp,
    warningOutline,
    warningSharp,
    searchOutline,
    searchSharp,
    logInOutline,
    logInSharp,
    logOutSharp,
    logOutOutline,
    homeOutline,
    homeSharp,
    optionsOutline,
    optionsSharp,
    filterCircleOutline,
    filterCircleSharp,
    addOutline,
    addSharp,
    documentTextOutline,
    documentTextSharp,
    cardOutline,
    cardSharp,
} from "ionicons/icons";
import { TokenService } from "@/services/token.service";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Menu",
    components: {
        IonContent,
        IonIcon,
        IonItem,
        IonLabel,
        IonList,
        // IonListHeader,
        IonMenu,
        IonMenuToggle,
        IonButton,
        IonHeader,
        IonToolbar
    },
    setup() {
        // Add database calls to get volume of actionable, overdue, etc
        const selectedIndex = ref(0);
        const appPages = [
            {
                title: "Home",
                url: "/",
                iosIcon: homeOutline,
                mdIcon: homeSharp,
            },
            {
                title: "Search",
                url: "/search",
                iosIcon: searchOutline,
                mdIcon: searchSharp,
            },
            {
                title: "Actionable",
                url: "/actionable",
                iosIcon: warningOutline,
                mdIcon: warningSharp,
            },
            {
                title: "Orders",
                url: "/orders",
                iosIcon: documentTextOutline,
                mdIcon: documentTextSharp,
            },
            // {
            //     title: "Pricing",
            //     url: "/pricing",
            //     iosIcon: cardOutline,
            //     mdIcon: cardSharp,
            // },

            {
                title: "Manage User",
                url: "/manage-user",
                iosIcon: optionsOutline,
                mdIcon: optionsSharp,
            },
            {
                title: "View Products",
                url: "/view-product",
                iosIcon: filterCircleOutline,
                mdIcon: filterCircleSharp,
            },
            {
                title: "Add Product",
                url: "/add-product",
                iosIcon: addOutline,
                mdIcon: addSharp,
            },
            {
                title: "Product Attributes",
                url: "/view-product-attributes",
                iosIcon: addOutline,
                mdIcon: addSharp,
            },
        ];

        const path = window.location.pathname.split("folder/")[1];
        if (path !== undefined) {
            selectedIndex.value = appPages.findIndex(
                (page) => page.title.toLowerCase() === path.toLowerCase()
            );
        }

        const route = useRoute();
        const router = useRouter();

        return {
            selectedIndex,
            appPages,
            // loggedOutPages,
            route,
            // labels,
            archiveOutline,
            archiveSharp,
            bookmarkOutline,
            bookmarkSharp,
            heartOutline,
            heartSharp,
            mailOutline,
            mailSharp,
            paperPlaneOutline,
            paperPlaneSharp,
            trashOutline,
            trashSharp,
            warningOutline,
            warningSharp,
            searchOutline,
            searchSharp,
            logInOutline,
            logInSharp,
            logOutSharp,
            logOutOutline,
            router,
            isSelected: (url: string) => (url === route.path ? "selected" : ""),
        };
    },
    computed: {
        ...mapGetters("auth", ["authenticating", "authenticationError", "authenticationErrorCode"]),
    },
    watch: {
        loggedIn: function () {
            return !!TokenService.getToken();
        },
    },
    methods: {
        ...mapActions("auth", ["signOut"]),
        async logOut() {
            this.signOut().then(() => {
                this.router.push("/login");
            });
        },
    },
});
