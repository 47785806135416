import { TBrand } from "@/types/Brand";

type TBrandState = {
    brands: Array<TBrand>;
};

const state: TBrandState = {
    brands: [
        { name: "Apple", types: [1, 2, 3, 4] },
        { name: "Samsung", types: [1, 2, 3, 4] },
    ],
};

const getters = {
    brands: (state: TBrandState) => state.brands,
};

export const brand = { state, getters };
