import ApiService from "@/services/api.service";
import { TVariant, TVariantOption, TVariantPrice } from "@/types/Variant";

type TVariantState = {
    loading: boolean;
    variants: Array<TVariant>;
};

const state: TVariantState = {
    loading: false,
    variants: [],
};

const getters = {
    byId: (state: TVariantState) => (id: number) => state.variants.find((v) => v.id === id),
    byProduct: (state: TVariantState) => (id: number) =>
        state.variants.filter((v) => v.product_id == id),
};

const actions = {
    async fetchVariants(context: any, id: number) {
        context.commit("loadingVariants");
        try {
            const result = await ApiService.get(`/products/${id}/variants?admin=1`);
            context.commit("setVariants", { id, variants: result.data });
            return result.data;
        } catch (err) {
            console.log(err);
            context.commit("setError");
        }
    },
    async fetchVariant(context: any, id: number) {
        context.commit("loadingVariants");
        try {
            const result = await ApiService.get(`/variants/${id}?options=1&prices=1`);
            context.commit("setVariant", { id, variant: result.data });
            return result.data;
        } catch (err) {
            console.log(err);
            context.commit("setError");
        }
    },
    async updateVariant(context: any, variant: Partial<TVariant>) {
        context.commit("loadingVariants");
        try {
            const result = await ApiService.put(`/variants/${variant.id}`, variant);
            context.commit("updateVariant", { variant: result.data });
        } catch (err) {
            console.log(err);
            context.commit("setError");
        }
    },
    async createVariant(context: any, variant: TVariant) {
        context.commit("loadingVariants");
        try {
            const result = await ApiService.post("/variants/all", variant);
            context.commit("setVariant", { variant: result.data });
        } catch (err) {
            console.log(err);
            context.commit("setError");
        }
    },
    async delete(context: any, id: number) {
        context.commit("loadingVariants");
        try {
            await ApiService.delete(`/variants/${id}`);
            context.commit("removeVariant", { id });
        } catch (err) {
            console.log(err);
            context.commit("setError");
        }
    },
    async updateOptions(
        context: any,
        data: { variantId: number; options: Array<Partial<TVariantOption>> }
    ) {
        const { options, variantId } = data;
        context.commit("loadingVariants");
        try {
            await ApiService.put("/variantoptions/bulk", { options });
            context.commit("updateVariantOptions", { variantId, options });
        } catch (err) {
            console.log(err);
            context.commit("setError");
        }
    },
    async updatePrice(context: any, data: { variantId: number; price: TVariantPrice }) {
        const { price, variantId } = data;
        context.commit("loadingVariants");
        try {
            await ApiService.put(`/prices/${price.id}`, price);
            context.commit("updateVariantPrice", { variantId, price });
        } catch (err) {
            console.log(err);
            context.commit("setError");
        }
    },
};

const mutations = {
    loadingVariants(state: TVariantState) {
        state.loading = true;
    },
    setVariants(state: TVariantState, payload: { id: number; variants: Array<TVariant> }) {
        state.loading = false;
        state.variants = [
            ...state.variants.filter((v) => v.product_id !== payload.id),
            ...payload.variants.map((v) => ({ ...v, product_id: payload.id })),
        ];
    },
    setVariant(state: TVariantState, payload: { variant: TVariant }) {
        state.loading = false;
        state.variants = [...state.variants, payload.variant];
    },
    updateVariant(state: TVariantState, payload: { variant: TVariant }) {
        state.loading = false;
        state.variants = [
            ...state.variants.map((v) =>
                v.id == payload.variant.id ? { ...v, ...payload.variant } : v
            ),
        ];
    },
    removeVariant(state: TVariantState, payload: { id: number }) {
        state.variants = [...state.variants.filter((v) => v.id != payload.id)];
    },
    updateVariantOptions(
        state: TVariantState,
        payload: { variantId: number; options: Array<TVariantOption> }
    ) {
        state.loading = false;
        const variant = state.variants.find((v) => v.id === payload.variantId);
        if (variant) {
            const updateVariant = { ...variant, options: payload.options };
            state.variants = [
                ...state.variants.map((v) =>
                    v.id === updateVariant.id ? { ...updateVariant } : v
                ),
            ];
        }
    },
    updateVariantPrice(state: TVariantState, payload: { variantId: number; price: TVariantPrice }) {
        state.loading = false;
        const variant = state.variants.find((v) => v.id === payload.variantId);

        if (variant) {
            const updateVariant = { ...variant, price: payload.price };

            state.variants = [
                ...state.variants.map((v) =>
                    v.id === updateVariant.id ? { ...updateVariant } : v
                ),
            ];
        }
    },
    setError(state: TVariantState) {
        state.loading = false;
    },
};

export const variant = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
