import { TokenService } from "@/services/token.service";
import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: () => import("../views/HomePage.vue"),
    },
    {
        path: "/actionable",
        component: () => import("../views/ActionablePage.vue"),
    },
    {
        path: "/orders",
        component: () => import("../views/OrdersPage.vue"),
    },
    {
        path: "/pricing",
        component: () => import("../views/PricingPage.vue"),
    },
    {
        path: "/order-detail/:id",
        component: () => import("../views/OrderDetailPage.vue"),
    },
    {
        path: "/manage-user",
        component: () => import("../views/ManageUser.vue"),
    },
    {
        path: "/view-product",
        component: () => import("../views/ViewProduct.vue"),
    },
    {
        path: "/configure-product-variants",
        component: () => import("../views/ConfigureProductVariants.vue"),
    },
    {
        path: "/review-and-add-product-db/:id",
        component: () => import("../views/ReviewAddProdcutsDB.vue"),
    },
    {
        path: "/view-product-attributes",
        component: () => import("../views/ViewProductAttributes.vue"),
    },
    {
        path: "/add-product-attribute",
        component: () => import("../views/AddNewAttribute.vue"),
    },
    {
        path: "/configure-product-attribute/:id",
        component: () => import("../views/ConfigureProductAttributes.vue"),
    },
    {
        path: "/attribute-values/:id",
        component: () => import("../views/AttributeValues.vue"),
    },
    {
        path: "/add-new-attribute",
        component: () => import("../views/AddNewAttribute.vue"),
    },

    {
        path: "/edit-product/:id",
        component: () => import("../views/EditProduct.vue"),
    },
    {
        path: "/add-product",
        component: () => import("../views/AddProduct.vue"),
    },
    {
        path: "/search",
        component: () => import("../views/SearchPage.vue"),
    },
    {
        path: "/login",
        component: () => import("../views/LoginPage.vue"),
        meta: {
            public: true,
            onlyWhenLoggedOut: true,
        },
    },
    {
        path: "/add-new-user",
        component: () => import("../views/AddNewUser.vue"),
    },
    {
        path: "/order-detail/:id",
        component: () => import("../views/OrderDetailPage.vue"),
    },
    {
        path: "/manage-variants",
        component: () => import("../views/ManageVariants.vue"),
    },
    {
        path: "/edit-product-variant/:id",
        component: () => import("../views/EditProductVariant.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const isPublic = to.matched.some((record) => record.meta.public);
    const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut);
    const loggedIn = !!TokenService.getToken();

    if (!isPublic && !loggedIn) {
      return next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    }

    if (loggedIn && onlyWhenLoggedOut) {
      return next("/");
    }

    next();
});

export default router;
