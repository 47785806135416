
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent } from 'vue';
import Menu from './components/menu.vue';
import { store } from './store';
// import { useRoute } from 'vue-router';
// import { archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    Menu
  },
  mounted() {
    store.dispatch("category/fetchCategories");
    store.dispatch("option/fetchOptions");
  },
  setup() {
    // const selectedIndex = ref(0);
    // const appPages = [
    //   {
    //     title: 'Inbox',
    //     url: '/folder/Inbox',
    //     iosIcon: mailOutline,
    //     mdIcon: mailSharp
    //   },
    //   {
    //     title: 'Outbox',
    //     url: '/folder/Outbox',
    //     iosIcon: paperPlaneOutline,
    //     mdIcon: paperPlaneSharp
    //   },
    //   {
    //     title: 'Favorites',
    //     url: '/folder/Favorites',
    //     iosIcon: heartOutline,
    //     mdIcon: heartSharp
    //   },
    //   {
    //     title: 'Archived',
    //     url: '/folder/Archived',
    //     iosIcon: archiveOutline,
    //     mdIcon: archiveSharp
    //   },
    //   {
    //     title: 'Trash',
    //     url: '/folder/Trash',
    //     iosIcon: trashOutline,
    //     mdIcon: trashSharp
    //   },
    //   {
    //     title: 'Spam',
    //     url: '/folder/Spam',
    //     iosIcon: warningOutline,
    //     mdIcon: warningSharp
    //   }
    // ];
    // const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

    // const path = window.location.pathname.split('folder/')[1];
    // if (path !== undefined) {
    //   selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    // }

    // const route = useRoute();

    return {
      // isSelected: (url: string) => url === route.path ? 'selected' : ''
    }
  }
});
