import { TCategory } from "@/types/Category";
import ApiService from "../services/api.service";

type TCategoryState = { loading: boolean; categories: Array<TCategory> };

const state: TCategoryState = {
    loading: false,
    categories: [],
};

const getters = {
    getAllCategories: (state: TCategoryState) => state.categories,
    getProductCategories: (state: TCategoryState) => {
        return state.categories.filter((c) => c.category_type === "contains_products");
    },
};

const actions = {
    async fetchCategories(context: any) {
        context.commit("loadingCategories");
        try {
            const result = await ApiService.get("/categories");
            context.commit("setCategories", { data: result.data.results });
        } catch (err) {
            console.log(err);
        }
    },
};

const mutations = {
    loadingCategories(state: TCategoryState) {
        state.loading = true;
    },
    setCategories(state: TCategoryState, payload: { data: Array<any> }) {
        state.loading = false;
        state.categories = payload.data;
    },
};

export const category = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
