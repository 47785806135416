import { createStore } from "vuex";
import { auth } from "./auth.store";
import { home } from "./home.store";
import { category } from "./category.store";
import { option } from "./option.store";
import { product } from "./product.store";
import { brand } from "./brand.store";
import { variant } from "./variant.store";
import { user } from "./user.store";

export const store = createStore({
    state: {
        types: [
            { id: 1, name: "Mobile Phone" },
            { id: 2, name: "Tablet" },
            { id: 3, name: "Watch" },
            { id: 4, name: "Laptop" },
        ],
    },
    mutations: {},
    actions: {},
    getters: {
        types: (state: { types: Array<any> }) => state.types,
    },
    modules: {
        auth,
        home,
        category,
        option,
        product,
        brand,
        variant,
        user,
    },
});
